import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingBadgeAndLeadingAvatarPreview(props) {
  const {
    ListTrailingBadgeOneLineAvatar,
    ListTrailingBadgeTwoLinesAvatar,
    ListTrailingBadgeThreeLinesAvatar,
  } = props

  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingBadgeOneLineAvatar}
        componentName="ListTrailingBadgeOneLineAvatar"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>

              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="w-full truncate text-base text-slate-700">
                  Ellen Ripley
                </h4>
              </div>
              <span className="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                2<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="w-full truncate text-base text-slate-700">
                  Thomas Anderson
                </h4>
              </div>
              <span className="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                4<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="w-full truncate text-base text-slate-700">
                  Luke Skywalker
                </h4>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="w-full truncate text-base text-slate-700">
                  Sarah Connor
                </h4>
              </div>
              <span className="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                1<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="w-full truncate text-base text-slate-700">
                  Captain James T. Kirk
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingBadgeTwoLinesAvatar}
        componentName="ListTrailingBadgeTwoLinesAvatar"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Ellen Ripley
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A tough, resourceful space officer and alien-fighting
                  survivor.
                </p>
              </div>
              <span className="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                4<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Thomas Anderson
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  The chosen one, a hacker who can bend the rules of reality.
                </p>
              </div>
              <span className="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                2<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Luke Skywalker
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A Jedi warrior who fights against the dark side of the Force.
                </p>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Sarah Connor
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A fierce resistance leader who fights against machines and
                  cyborgs from the future.
                </p>
              </div>
              <span className="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                1<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Captain James T. Kirk
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A charismatic and adventurous Starfleet captain who explores
                  the final frontier.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingBadgeThreeLinesAvatar}
        componentName="ListTrailingBadgeThreeLinesAvatar"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Ellen Ripley
                </h4>
                <p className="w-full text-sm text-slate-500">
                  A tough, resourceful space officer and survivor.
                </p>
              </div>

              <span className="inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-sm text-white">
                4<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Thomas Anderson
                </h4>
                <p className="w-full text-sm text-slate-500">
                  The chosen one, a hacker who can bend the rules.
                </p>
              </div>
              <span className="inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-sm text-white">
                2<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Luke Skywalker
                </h4>
                <p className="w-full text-sm text-slate-500">
                  A Jedi warrior who fights against the dark side.
                </p>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Sarah Connor
                </h4>
                <p className="w-full text-sm text-slate-500">
                  A fierce resistance leader who fights against machines.
                </p>
              </div>
              <span className="inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-sm text-white">
                1<span className="sr-only"> new emails</span>
              </span>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Captain James T. Kirk
                </h4>
                <p className="w-full text-sm text-slate-500">
                  A charismatic and adventurous captain.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingBadgeAndLeadingAvatarPreview
