import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingBadgeAndLeadingIconPreview(props) {
  const {
    ListTrailingBadgeOneLineIcon,
    ListTrailingBadgeTwoLinesIcon,
    ListTrailingBadgeThreeLinesIcon,
  } = props
  return (
    <div class="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingBadgeOneLineIcon}
        componentName="ListTrailingBadgeOneLineIcon"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Dashboard icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </div>

              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Dashboard
                </h4>
              </div>
              <span class="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                2<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="User icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  User profile
                </h4>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Analytics icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Analytics
                </h4>
              </div>
              <span class="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                4<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Cloud icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Cloud settings
                </h4>
              </div>
              <span class="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                1<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Dashboard icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Settings
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingBadgeTwoLinesIcon}
        componentName="ListTrailingBadgeTwoLinesIcon"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Dashboard icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Dashboard
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  Key metrics and data visualizations.
                </p>
              </div>
              <span class="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                2<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="User icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  User profile
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  Main user settings, and preferences.
                </p>
              </div>
              <span class="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                4<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Analytics icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Analytics
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  Insights and performance metrics.
                </p>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Cloud icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Cloud storage
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  Cloud-based storage and settings.
                </p>
              </div>
              <span class="inline-flex items-center justify-center rounded-full bg-pink-500 px-1.5 text-xs text-white">
                1<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Dashboard icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Settings
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  App settings and configurations.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingBadgeThreeLinesIcon}
        componentName="ListTrailingBadgeThreeLinesIcon"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-start gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Dashboard icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Dashboard
                </h4>
                <p class="w-full text-sm text-slate-500">
                  The main hub of the app, displaying data visualizations.
                </p>
              </div>
              <span class="inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-sm text-white">
                2<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-start gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="User icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  User profile
                </h4>
                <p class="w-full text-sm text-slate-500">
                  personal information, settings, and preferences for the
                  logged-in user.
                </p>
              </div>
              <span class="inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-sm text-white">
                4<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-start gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Analytics icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Analytics
                </h4>
                <p class="w-full text-sm text-slate-500">
                  Detailed insights and metrics for the app's usage and traffic.
                </p>
              </div>
            </li>
            <li class="flex items-start gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Cloud icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Cloud storage
                </h4>
                <p class="w-full text-sm text-slate-500">
                  Cloud-based storage, security settings, and access
                  permissions.
                </p>
              </div>
              <span class="inline-flex items-center justify-center gap-1 rounded-full bg-pink-500 px-1.5 text-sm text-white">
                1<span class="sr-only"> new notifications</span>
              </span>
            </li>
            <li class="flex items-start gap-4 px-4 py-3">
              <div class="flex items-center text-emerald-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                  aria-label="Dashboard icon"
                  role="graphics-symbol"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Settings
                </h4>
                <p class="w-full text-sm text-slate-500">
                  Settings and configurations for notifications and privacy.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingBadgeAndLeadingIconPreview
