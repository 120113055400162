import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithLeadingImagePreview(props) {
  const {
    ListLeadingImageOneLine,
    ListLeadingImageTwoLines,
    ListLeadingImageThreeLines,
  } = props

  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListLeadingImageOneLine}
        componentName="ListLeadingImageOneLine"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Velocity Boost 2023{" "}
                </h4>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Gravity Glide v2{" "}
                </h4>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  S4 Turbo Charge{" "}
                </h4>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Flex Fit{" "}
                </h4>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Endurance Pro{" "}
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListLeadingImageTwoLines}
        componentName="ListLeadingImageTwoLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Velocity Boost 2023{" "}
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Designed for speed and agility, perfect for runners.
                </p>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Gravity Glide v2 </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A shoe that provides superior cushioning and support, ideal
                  for basketball players.
                </p>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">S4 Turbo Charge </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A lightweight shoe that boosts performance for all kinds of
                  athletes.
                </p>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Flex Fit </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Offers flexible and adaptive support for a range of
                  activities, from yoga to cross-training.
                </p>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Endurance Pro </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  A durable shoe that's built to last, suitable for
                  long-distance running and endurance sports.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListLeadingImageThreeLines}
        componentName="ListLeadingImageThreeLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Velocity Boost 2023{" "}
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Designed for speed and agility, perfect for runners.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Gravity Glide v2 </h4>
                <p className="w-full text-sm text-slate-500">
                  A shoe for superior cushioning and support.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">S4 Turbo Charge </h4>
                <p className="w-full text-sm text-slate-500">
                  A lightweight shoe for all kinds of athletes.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Flex Fit </h4>
                <p className="w-full text-sm text-slate-500">
                  Flexible & adaptive support for a range of activities.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Endurance Pro </h4>
                <p className="w-full text-sm text-slate-500">
                  A durable shoe that's built to last and long-distance.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithLeadingImagePreview
