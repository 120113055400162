import React, { useState, useRef } from "react"
import { Link } from "gatsby"
// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ogImage from "../../../images/seo/lists/lists.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
// Hero
import ListHero from "../../../library/components/list/react/_preview/hero_list"
// Preview
import SimpleTextListsPreview from "../../../library/components/list/react/_preview/simple_text_lists"
import ListWithLeadingAvatarPreview from "../../../library/components/list/react/_preview/list_with_leading_avatar"
import ListsWithLeadingIconPreview from "../../../library/components/list/react/_preview/list_with_leading_icon"
import ListsWithLeadingImagePreview from "../../../library/components/list/react/_preview/list_with_leading_image"
import ListsWithLeadingCheckboxPreview from "../../../library/components/list/react/_preview/list_with_leading_checkbox"
import TrailingTextListPreview from "../../../library/components/list/react/_preview/trailing_text_list"
import ListsWithTrailingTextAndLeadingAvatarPreview from "../../../library/components/list/react/_preview/list_with_trailing_text_and_leading_avatar"
import ListsWithTrailingTextAndLeadingImagePreview from "../../../library/components/list/react/_preview/list_with_trailing_text_and_leading_image"
import ListsWithTrailingIconAndLeadingAvatarPreview from "../../../library/components/list/react/_preview/list_with_trailing_icon_and_leading_avatar"
import ListsWithTrailingIconAndLeadingIconPreview from "../../../library/components/list/react/_preview/list_with_trailing_icon_and_leading_icon"
import ListsWithTrailingCheckboxPreview from "../../../library/components/list/react/_preview/list_with_trailing_checkbox"
import ListsWithTrailingCheckboxAndLeadingAvatarPreview from "../../../library/components/list/react/_preview/list_with_trailing_checkbox_and_leading_avatar"
import ListsWithTrailingCheckboxAndLeadingIconPreview from "../../../library/components/list/react/_preview/list_with_trailing_checkbox_and_leading_icon"
import ListTrailingCheckboxListWithLeadingImagePreview from "../../../library/components/list/react/_preview/trailing_checkbox_list_with_leading_image"
import ListsWithTrailingBadgeAndLeadingAvatarPreview from "../../../library/components/list/react/_preview/list_with_trailing_badge_and_leading_avatar"
import ListsWithTrailingBadgeAndLeadingIconPreview from "../../../library/components/list/react/_preview/list_with_trailing_badge_and_leading_icon"
import ListsWithTrailingSwitchPreview from "../../../library/components/list/react/_preview/list_with_trailing_switch"
import ListsWithTrailingSwitchAndLeadingIconPreview from "../../../library/components/list/react/_preview/list_with_trailing_switch_and_leading_icon"

// Simple Text lists
const ListTextOneLineJsx = require("!!raw-loader!../../../library/components/list/react/text/oneline.jsx")
const ListTextOneLineHTML = require("!!raw-loader!../../../library/components/list/html/text/oneline.html")

const ListTextTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/text/twolines.jsx")
const ListTextTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/text/twolines.html")

const ListTextThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/text/threelines.jsx")
const ListTextThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/text/threelines.html")

// Lists with leading avatar
const ListLeadingAvatarOneLineJsx = require("!!raw-loader!../../../library/components/list/react/leading-avatar/oneline.jsx")
const ListLeadingAvatarOneLineHTML = require("!!raw-loader!../../../library/components/list/html/leading-avatar/oneline.html")

const ListLeadingAvatarTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-avatar/twolines.jsx")
const ListLeadingAvatarTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-avatar/twolines.html")

const ListLeadingAvatarThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-avatar/threelines.jsx")
const ListLeadingAvatarThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-avatar/threelines.html")

// Lists with leading icon
const ListLeadingIconOneLineJsx = require("!!raw-loader!../../../library/components/list/react/leading-icon/oneline.jsx")
const ListLeadingIconOneLineHTML = require("!!raw-loader!../../../library/components/list/html/leading-icon/oneline.html")

const ListLeadingIconTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-icon/twolines.jsx")
const ListLeadingIconTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-icon/twolines.html")

const ListLeadingIconThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-icon/threelines.jsx")
const ListLeadingIconThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-icon/threelines.html")

// Lists with leading image
const ListLeadingImageOneLineJsx = require("!!raw-loader!../../../library/components/list/react/leading-image/oneline.jsx")
const ListLeadingImageOneLineHTML = require("!!raw-loader!../../../library/components/list/html/leading-image/oneline.html")

const ListLeadingImageTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-image/twolines.jsx")
const ListLeadingImageTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-image/twolines.html")

const ListLeadingImageThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-image/threelines.jsx")
const ListLeadingImageThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-image/threelines.html")

// Lists with leading checkbox
const ListLeadingCheckboxOneLineJsx = require("!!raw-loader!../../../library/components/list/react/leading-checkbox/oneline.jsx")
const ListLeadingCheckboxOneLineHTML = require("!!raw-loader!../../../library/components/list/html/leading-checkbox/oneline.html")

const ListLeadingCheckboxTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-checkbox/twolines.jsx")
const ListLeadingCheckboxTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-checkbox/twolines.html")

const ListLeadingCheckboxThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/leading-checkbox/threelines.jsx")
const ListLeadingCheckboxThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/leading-checkbox/threelines.html")

// Trailing Text list
const ListTrailingTextOneLineJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/oneline-text.jsx")
const ListTrailingTextOneLineHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/oneline-text.html")

const ListTrailingTextTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/twolines-text.jsx")
const ListTrailingTextTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/twolines-text.html")

const ListTrailingTextThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/threelines-text.jsx")
const ListTrailingTextThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/threelines-text.html")

// Lists with trailing text and leading avatar
const ListTrailingTextOneLineAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/oneline-avatar-text.jsx")
const ListTrailingTextOneLineAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/oneline-avatar-text.html")

const ListTrailingTextTwoLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/twolines-avatar-text.jsx")
const ListTrailingTextTwoLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/twolines-avatar-text.html")

const ListTrailingTextThreeLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/threelines-avatar-text.jsx")
const ListTrailingTextThreeLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/threelines-avatar-text.html")

// Lists with trailing text and leading image
const ListTrailingTextOneLineImageJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/oneline-image-text.jsx")
const ListTrailingTextOneLineImageHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/oneline-image-text.html")

const ListTrailingTextTwoLinesImageJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/twolines-image-text.jsx")
const ListTrailingTextTwoLinesImageHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/twolines-image-text.html")

const ListTrailingTextThreeLinesImageJsx = require("!!raw-loader!../../../library/components/list/react/trailing-text/threelines-image-text.jsx")
const ListTrailingTextThreeLinesImageHTML = require("!!raw-loader!../../../library/components/list/html/trailing-text/threelines-image-text.html")

// Lists with trailing icon and leading avatar
const ListTrailingIconOneLineAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-icon/oneline-avatar-icon.jsx")
const ListTrailingIconOneLineAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-icon/oneline-avatar-icon.html")

const ListTrailingIconTwoLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-icon/twolines-avatar-icon.jsx")
const ListTrailingIconTwoLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-icon/twolines-avatar-icon.html")

const ListTrailingIconThreeLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-icon/threelines-avatar-icon.jsx")
const ListTrailingIconThreeLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-icon/threelines-avatar-icon.html")

// Lists with trailing icon and leading icon
const ListTrailingIconOneLineJsx = require("!!raw-loader!../../../library/components/list/react/trailing-icon/oneline-icon-icon.jsx")
const ListTrailingIconOneLineHTML = require("!!raw-loader!../../../library/components/list/html/trailing-icon/oneline-icon-icon.html")

const ListTrailingIconTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-icon/twolines-icon-icon.jsx")
const ListTrailingIconTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-icon/twolines-icon-icon.html")

const ListTrailingIconThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-icon/threelines-icon-icon.jsx")
const ListTrailingIconThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-icon/threelines-icon-icon.html")

// Lists with trailing checkbox
const ListTrailingCheckboxOneLineJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/oneline-checkbox.jsx")
const ListTrailingCheckboxOneLineHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/oneline-checkbox.html")

const ListTrailingCheckboxTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/twolines-checkbox.jsx")
const ListTrailingCheckboxTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/twolines-checkbox.html")

const ListTrailingCheckboxThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/threelines-checkbox.jsx")
const ListTrailingCheckboxThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/threelines-checkbox.html")

// Lists with trailing checkbox and leading avatar
const ListTrailingCheckboxOneLineAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/oneline-avatar-checkbox.jsx")
const ListTrailingCheckboxOneLineAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/oneline-avatar-checkbox.html")

const ListTrailingCheckboxTwoLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/twolines-avatar-checkbox.jsx")
const ListTrailingCheckboxTwoLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/twolines-avatar-checkbox.html")

const ListTrailingCheckboxThreeLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/threelines-avatar-checkbox.jsx")
const ListTrailingCheckboxThreeLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/threelines-avatar-checkbox.html")

// Lists with trailing checkbox and leading icon
const ListTrailingCheckboxOneLineIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/oneline-icon-checkbox.jsx")
const ListTrailingCheckboxOneLineIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/oneline-icon-checkbox.html")

const ListTrailingCheckboxTwoLinesIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/twolines-icon-checkbox.jsx")
const ListTrailingCheckboxTwoLinesIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/twolines-icon-checkbox.html")

const ListTrailingCheckboxThreeLinesIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/threelines-icon-checkbox.jsx")
const ListTrailingCheckboxThreeLinesIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/threelines-icon-checkbox.html")

// Lists with trailing checkbox with leading image
const ListTrailingCheckboxOneLineImageJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/oneline-image-checkbox.jsx")
const ListTrailingCheckboxOneLineImageHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/oneline-image-checkbox.html")

const ListTrailingCheckboxTwoLinesImageJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/twolines-image-checkbox.jsx")
const ListTrailingCheckboxTwoLinesImageHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/twolines-image-checkbox.html")

const ListTrailingCheckboxThreeLinesImageJsx = require("!!raw-loader!../../../library/components/list/react/trailing-checkbox/threelines-image-checkbox.jsx")
const ListTrailingCheckboxThreeLinesImageHTML = require("!!raw-loader!../../../library/components/list/html/trailing-checkbox/threelines-image-checkbox.html")

// Lists with trailing badge and leading avatar
const ListTrailingBadgeOneLineAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-badge/oneline-avatar-badge.jsx")
const ListTrailingBadgeOneLineAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-badge/oneline-avatar-badge.html")

const ListTrailingBadgeTwoLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-badge/twolines-avatar-badge.jsx")
const ListTrailingBadgeTwoLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-badge/twolines-avatar-badge.html")

const ListTrailingBadgeThreeLinesAvatarJsx = require("!!raw-loader!../../../library/components/list/react/trailing-badge/threelines-avatar-badge.jsx")
const ListTrailingBadgeThreeLinesAvatarHTML = require("!!raw-loader!../../../library/components/list/html/trailing-badge/threelines-avatar-badge.html")

// Lists with trailing badge and leading icon
const ListTrailingBadgeOneLineIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-badge/oneline-icon-badge.jsx")
const ListTrailingBadgeOneLineIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-badge/oneline-icon-badge.html")

const ListTrailingBadgeTwoLinesIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-badge/twolines-icon-badge.jsx")
const ListTrailingBadgeTwoLinesIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-badge/twolines-icon-badge.html")

const ListTrailingBadgeThreeLinesIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-badge/threelines-icon-badge.jsx")
const ListTrailingBadgeThreeLinesIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-badge/threelines-icon-badge.html")

// Lists with trailing switch
const ListTrailingSwitchOneLineJsx = require("!!raw-loader!../../../library/components/list/react/trailing-switch/oneline-switch.jsx")
const ListTrailingSwitchOneLineHTML = require("!!raw-loader!../../../library/components/list/html/trailing-switch/oneline-switch.html")

const ListTrailingSwitchTwoLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-switch/twolines-switch.jsx")
const ListTrailingSwitchTwoLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-switch/twolines-switch.html")

const ListTrailingSwitchThreeLinesJsx = require("!!raw-loader!../../../library/components/list/react/trailing-switch/threelines-switch.jsx")
const ListTrailingSwitchThreeLinesHTML = require("!!raw-loader!../../../library/components/list/html/trailing-switch/threelines-switch.html")

// Lists with trailing switch and leading icon
const ListTrailingSwitchOneLineIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-switch/oneline-icon-switch.jsx")
const ListTrailingSwitchOneLineIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-switch/oneline-icon-switch.html")

const ListTrailingSwitchTwoLinesIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-switch/twolines-icon-switch.jsx")
const ListTrailingSwitchTwoLinesIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-switch/twolines-icon-switch.html")

const ListTrailingSwitchThreeLinesIconJsx = require("!!raw-loader!../../../library/components/list/react/trailing-switch/threelines-icon-switch.jsx")
const ListTrailingSwitchThreeLinesIconHTML = require("!!raw-loader!../../../library/components/list/html/trailing-switch/threelines-icon-switch.html")

const ListsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "SimpleTextLists",
      title: "Simple text lists",
      active_tab: 1,
    },
    {
      component_name: "ListWithLeadingAvatar",
      title: "Lists with leading avatar",
      active_tab: 1,
    },
    {
      component_name: "ListsWithLeadingIcon",
      title: "With leading icon",
      active_tab: 1,
    },
    {
      component_name: "ListsWithLeadingImage",
      title: "With leading image",
      active_tab: 1,
    },
    {
      component_name: "ListsWithLeadingCheckbox",
      title: "With leading checkbox",
      active_tab: 1,
    },
    {
      component_name: "TrailingTextList",
      title: "Trailing text",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingTextAndLeadingAvatar",
      title: "Τrailing text and leading avatar",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingTextAndLeadingImage",
      title: "Τrailing text and leading image",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingIconAndLeadingAvatar",
      title: "Τrailing icon and leading avatar",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingIconAndLeadingIcon",
      title: "Τrailing icon and leading icon",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingCheckbox",
      title: "With trailing checkbox",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingCheckboxAndLeadingAvatar",
      title: "Τrailing checkbox and leading avatar",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingCheckboxAndLeadingIcon",
      title: "Τrailing checkbox and leading icon",
      active_tab: 1,
    },
    {
      component_name: "ListTrailingCheckboxListWithLeadingImage",
      title: "Τrailing checkbox with leading image",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingBadgeAndLeadingAvatar",
      title: "Τrailing badge and leading avatar",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingBadgeAndLeadingIcon",
      title: "Τrailing badge and leading icon",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingSwitch",
      title: " With trailing switch",
      active_tab: 1,
    },
    {
      component_name: "ListsWithTrailingSwitchAndLeadingIcon",
      title: "Τrailing switch and leading icon",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name !== id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Lists - WindUI Component Library"
        ogtitle="Tailwind CSS Lists - WindUI Component Library"
        description="List components are a continuous group of items in a structured format, that contain primary and supplemental actions. Built with Tailwind CSS by WindUI."
        ogdescription="List components are a continuous group of items in a structured format, that contain primary and supplemental actions. Built with Tailwind CSS by WindUI."
        url="components/lists/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Lists, Lists, List components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Lists</h1>
          <p>
            Lists are a continuous group of items in a structured format. They
            can contain primary and supplemental actions, which are often
            represented by icons, text and images.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full flex-col justify-center overflow-hidden rounded-xl bg-wuiSlate-50 p-8 py-9 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 md:p-16">
              <ListHero />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="SimpleTextLists">Simple text lists</h3>
          <PreviewBlock
            id="SimpleTextLists"
            HtmlComponent={ListTextOneLineHTML.default}
            JsxComponent={ListTextOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <SimpleTextListsPreview
                ListTextOneLine={
                  activeTabs[0].active_tab !== 1
                    ? ListTextOneLineJsx.default
                    : ListTextOneLineHTML.default
                }
                ListTextTwoLines={
                  activeTabs[0].active_tab !== 1
                    ? ListTextTwoLinesJsx.default
                    : ListTextTwoLinesHTML.default
                }
                ListTextThreeLines={
                  activeTabs[0].active_tab !== 1
                    ? ListTextThreeLinesJsx.default
                    : ListTextThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListWithLeadingAvatar">Lists with leading avatar</h3>
          <PreviewBlock
            id="ListWithLeadingAvatar"
            HtmlComponent={ListLeadingAvatarOneLineHTML.default}
            JsxComponent={ListLeadingAvatarOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListWithLeadingAvatarPreview
                ListLeadingAvatarOneLine={
                  activeTabs[1].active_tab !== 1
                    ? ListLeadingAvatarOneLineJsx.default
                    : ListLeadingAvatarOneLineHTML.default
                }
                ListLeadingAvatarTwoLines={
                  activeTabs[1].active_tab !== 1
                    ? ListLeadingAvatarTwoLinesJsx.default
                    : ListLeadingAvatarTwoLinesHTML.default
                }
                ListLeadingAvatarThreeLines={
                  activeTabs[1].active_tab !== 1
                    ? ListLeadingAvatarThreeLinesJsx.default
                    : ListLeadingAvatarThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithLeadingIcon">Lists with leading icon</h3>
          <PreviewBlock
            id="ListsWithLeadingIcon"
            HtmlComponent={ListLeadingIconOneLineHTML.default}
            JsxComponent={ListLeadingIconOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithLeadingIconPreview
                ListLeadingIconOneLine={
                  activeTabs[2].active_tab !== 1
                    ? ListLeadingIconOneLineJsx.default
                    : ListLeadingIconOneLineHTML.default
                }
                ListLeadingIconTwoLines={
                  activeTabs[2].active_tab !== 1
                    ? ListLeadingIconTwoLinesJsx.default
                    : ListLeadingIconTwoLinesHTML.default
                }
                ListLeadingIconThreeLines={
                  activeTabs[2].active_tab !== 1
                    ? ListLeadingIconThreeLinesJsx.default
                    : ListLeadingIconThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithLeadingImage">Lists with leading image</h3>
          <PreviewBlock
            id="ListsWithLeadingImage"
            HtmlComponent={ListLeadingImageOneLineHTML.default}
            JsxComponent={ListLeadingImageOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithLeadingImagePreview
                ListLeadingImageOneLine={
                  activeTabs[3].active_tab !== 1
                    ? ListLeadingImageOneLineJsx.default
                    : ListLeadingImageOneLineHTML.default
                }
                ListLeadingImageTwoLines={
                  activeTabs[3].active_tab !== 1
                    ? ListLeadingImageTwoLinesJsx.default
                    : ListLeadingImageTwoLinesHTML.default
                }
                ListLeadingImageThreeLines={
                  activeTabs[3].active_tab !== 1
                    ? ListLeadingImageThreeLinesJsx.default
                    : ListLeadingImageThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithLeadingCheckbox">Lists with leading checkbox</h3>
          <PreviewBlock
            id="ListsWithLeadingCheckbox"
            HtmlComponent={ListLeadingCheckboxOneLineHTML.default}
            JsxComponent={ListLeadingCheckboxOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithLeadingCheckboxPreview
                ListLeadingCheckboxOneLine={
                  activeTabs[4].active_tab !== 1
                    ? ListLeadingCheckboxOneLineJsx.default
                    : ListLeadingCheckboxOneLineHTML.default
                }
                ListLeadingCheckboxTwoLines={
                  activeTabs[4].active_tab !== 1
                    ? ListLeadingCheckboxTwoLinesJsx.default
                    : ListLeadingCheckboxTwoLinesHTML.default
                }
                ListLeadingCheckboxThreeLines={
                  activeTabs[4].active_tab !== 1
                    ? ListLeadingCheckboxThreeLinesJsx.default
                    : ListLeadingCheckboxThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TrailingTextList">Trailing text list</h3>
          <PreviewBlock
            id="TrailingTextList"
            HtmlComponent={ListTrailingTextOneLineHTML.default}
            JsxComponent={ListTrailingTextOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <TrailingTextListPreview
                ListTrailingTextOneLine={
                  activeTabs[5].active_tab !== 1
                    ? ListTrailingTextOneLineJsx.default
                    : ListTrailingTextOneLineHTML.default
                }
                ListTrailingTextTwoLines={
                  activeTabs[5].active_tab !== 1
                    ? ListTrailingTextTwoLinesJsx.default
                    : ListTrailingTextTwoLinesHTML.default
                }
                ListTrailingTextThreeLines={
                  activeTabs[5].active_tab !== 1
                    ? ListTrailingTextThreeLinesJsx.default
                    : ListTrailingTextThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingTextAndLeadingAvatar">
            Lists with trailing text and leading avatar
          </h3>
          <PreviewBlock
            id="ListsWithTrailingTextAndLeadingAvatar"
            HtmlComponent={ListTrailingTextOneLineAvatarHTML.default}
            JsxComponent={ListTrailingTextOneLineAvatarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingTextAndLeadingAvatarPreview
                ListTrailingTextOneLineAvatar={
                  activeTabs[6].active_tab !== 1
                    ? ListTrailingTextOneLineAvatarJsx.default
                    : ListTrailingTextOneLineAvatarHTML.default
                }
                ListTrailingTextTwoLinesAvatar={
                  activeTabs[6].active_tab !== 1
                    ? ListTrailingTextTwoLinesAvatarJsx.default
                    : ListTrailingTextTwoLinesAvatarHTML.default
                }
                ListTrailingTextThreeLinesAvatar={
                  activeTabs[6].active_tab !== 1
                    ? ListTrailingTextThreeLinesAvatarJsx.default
                    : ListTrailingTextThreeLinesAvatarHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingTextAndLeadingImage">
            Lists with trailing text and leading image
          </h3>
          <PreviewBlock
            id="ListsWithTrailingTextAndLeadingImage"
            HtmlComponent={ListTrailingTextOneLineImageHTML.default}
            JsxComponent={ListTrailingTextOneLineImageJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingTextAndLeadingImagePreview
                ListTrailingTextOneLineImage={
                  activeTabs[7].active_tab !== 1
                    ? ListTrailingTextOneLineImageJsx.default
                    : ListTrailingTextOneLineImageHTML.default
                }
                ListTrailingTextTwoLinesImage={
                  activeTabs[7].active_tab !== 1
                    ? ListTrailingTextTwoLinesImageJsx.default
                    : ListTrailingTextTwoLinesImageHTML.default
                }
                ListTrailingTextThreeLinesImage={
                  activeTabs[7].active_tab !== 1
                    ? ListTrailingTextThreeLinesImageJsx.default
                    : ListTrailingTextThreeLinesImageHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingIconAndLeadingAvatar">
            Lists with trailing icon and leading avatar
          </h3>
          <PreviewBlock
            id="ListsWithTrailingIconAndLeadingAvatar"
            HtmlComponent={ListTrailingIconOneLineAvatarHTML.default}
            JsxComponent={ListTrailingIconOneLineAvatarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingIconAndLeadingAvatarPreview
                ListTrailingIconOneLineAvatar={
                  activeTabs[8].active_tab !== 1
                    ? ListTrailingIconOneLineAvatarJsx.default
                    : ListTrailingIconOneLineAvatarHTML.default
                }
                ListTrailingIconTwoLinesAvatar={
                  activeTabs[8].active_tab !== 1
                    ? ListTrailingIconTwoLinesAvatarJsx.default
                    : ListTrailingIconTwoLinesAvatarHTML.default
                }
                ListTrailingIconThreeLinesAvatar={
                  activeTabs[8].active_tab !== 1
                    ? ListTrailingIconThreeLinesAvatarJsx.default
                    : ListTrailingIconThreeLinesAvatarHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingIconAndLeadingIcon">
            Lists with trailing icon and leading icon
          </h3>
          <PreviewBlock
            id="ListsWithTrailingIconAndLeadingIcon"
            HtmlComponent={ListTrailingIconOneLineHTML.default}
            JsxComponent={ListTrailingIconOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingIconAndLeadingIconPreview
                ListTrailingIconOneLine={
                  activeTabs[9].active_tab !== 1
                    ? ListTrailingIconOneLineJsx.default
                    : ListTrailingIconOneLineHTML.default
                }
                ListTrailingIconTwoLines={
                  activeTabs[9].active_tab !== 1
                    ? ListTrailingIconTwoLinesJsx.default
                    : ListTrailingIconTwoLinesHTML.default
                }
                ListTrailingIconThreeLines={
                  activeTabs[9].active_tab !== 1
                    ? ListTrailingIconThreeLinesJsx.default
                    : ListTrailingIconThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingCheckbox">Lists with trailing checkbox</h3>
          <PreviewBlock
            id="ListsWithTrailingCheckbox"
            HtmlComponent={ListTrailingCheckboxOneLineHTML.default}
            JsxComponent={ListTrailingCheckboxOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingCheckboxPreview
                ListTrailingCheckboxOneLine={
                  activeTabs[10].active_tab !== 1
                    ? ListTrailingCheckboxOneLineJsx.default
                    : ListTrailingCheckboxOneLineHTML.default
                }
                ListTrailingCheckboxTwoLines={
                  activeTabs[10].active_tab !== 1
                    ? ListTrailingCheckboxTwoLinesJsx.default
                    : ListTrailingCheckboxTwoLinesHTML.default
                }
                ListTrailingCheckboxThreeLines={
                  activeTabs[10].active_tab !== 1
                    ? ListTrailingCheckboxThreeLinesJsx.default
                    : ListTrailingCheckboxThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingCheckboxAndLeadingAvatar">
            Lists with trailing checkbox and leading avatar
          </h3>
          <PreviewBlock
            id="ListsWithTrailingCheckboxAndLeadingAvatar"
            HtmlComponent={ListTrailingCheckboxOneLineAvatarHTML.default}
            JsxComponent={ListTrailingCheckboxOneLineAvatarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingCheckboxAndLeadingAvatarPreview
                ListTrailingCheckboxOneLineAvatar={
                  activeTabs[11].active_tab !== 1
                    ? ListTrailingCheckboxOneLineAvatarJsx.default
                    : ListTrailingCheckboxOneLineAvatarHTML.default
                }
                ListTrailingCheckboxTwoLinesAvatar={
                  activeTabs[11].active_tab !== 1
                    ? ListTrailingCheckboxTwoLinesAvatarJsx.default
                    : ListTrailingCheckboxTwoLinesAvatarHTML.default
                }
                ListTrailingCheckboxThreeLinesAvatar={
                  activeTabs[11].active_tab !== 1
                    ? ListTrailingCheckboxThreeLinesAvatarJsx.default
                    : ListTrailingCheckboxThreeLinesAvatarHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingCheckboxAndLeadingIcon">
            Lists with trailing checkbox and leading icon
          </h3>
          <PreviewBlock
            id="ListsWithTrailingCheckboxAndLeadingIcon"
            HtmlComponent={ListTrailingCheckboxOneLineIconHTML.default}
            JsxComponent={ListTrailingCheckboxOneLineIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingCheckboxAndLeadingIconPreview
                ListTrailingCheckboxOneLineIcon={
                  activeTabs[12].active_tab !== 1
                    ? ListTrailingCheckboxOneLineIconJsx.default
                    : ListTrailingCheckboxOneLineIconHTML.default
                }
                ListTrailingCheckboxTwoLinesIcon={
                  activeTabs[12].active_tab !== 1
                    ? ListTrailingCheckboxTwoLinesIconJsx.default
                    : ListTrailingCheckboxTwoLinesIconHTML.default
                }
                ListTrailingCheckboxThreeLinesIcon={
                  activeTabs[12].active_tab !== 1
                    ? ListTrailingCheckboxThreeLinesIconJsx.default
                    : ListTrailingCheckboxThreeLinesIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListTrailingCheckboxListWithLeadingImage">
            Lists with trailing checkbox with leading image
          </h3>
          <PreviewBlock
            id="ListTrailingCheckboxListWithLeadingImage"
            HtmlComponent={ListTrailingCheckboxOneLineImageHTML.default}
            JsxComponent={ListTrailingCheckboxOneLineImageJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListTrailingCheckboxListWithLeadingImagePreview
                ListTrailingCheckboxOneLineImage={
                  activeTabs[13].active_tab !== 1
                    ? ListTrailingCheckboxOneLineImageJsx.default
                    : ListTrailingCheckboxOneLineImageHTML.default
                }
                ListTrailingCheckboxTwoLinesImage={
                  activeTabs[13].active_tab !== 1
                    ? ListTrailingCheckboxTwoLinesImageJsx.default
                    : ListTrailingCheckboxTwoLinesImageHTML.default
                }
                ListTrailingCheckboxThreeLinesImage={
                  activeTabs[13].active_tab !== 1
                    ? ListTrailingCheckboxThreeLinesImageJsx.default
                    : ListTrailingCheckboxThreeLinesImageHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingBadgeAndLeadingAvatar">
            Lists with trailing badge and leading avatar
          </h3>
          <PreviewBlock
            id="ListsWithTrailingBadgeAndLeadingAvatar"
            HtmlComponent={ListTrailingBadgeOneLineAvatarHTML.default}
            JsxComponent={ListTrailingBadgeOneLineAvatarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingBadgeAndLeadingAvatarPreview
                ListTrailingBadgeOneLineAvatar={
                  activeTabs[14].active_tab !== 1
                    ? ListTrailingBadgeOneLineAvatarJsx.default
                    : ListTrailingBadgeOneLineAvatarHTML.default
                }
                ListTrailingBadgeTwoLinesAvatar={
                  activeTabs[14].active_tab !== 1
                    ? ListTrailingBadgeTwoLinesAvatarJsx.default
                    : ListTrailingBadgeTwoLinesAvatarHTML.default
                }
                ListTrailingBadgeThreeLinesAvatar={
                  activeTabs[14].active_tab !== 1
                    ? ListTrailingBadgeThreeLinesAvatarJsx.default
                    : ListTrailingBadgeThreeLinesAvatarHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingBadgeAndLeadingIcon">
            Lists with trailing badge and leading icon
          </h3>
          <PreviewBlock
            id="ListsWithTrailingBadgeAndLeadingIcon"
            HtmlComponent={ListTrailingBadgeOneLineIconHTML.default}
            JsxComponent={ListTrailingBadgeOneLineIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingBadgeAndLeadingIconPreview
                ListTrailingBadgeOneLineIcon={
                  activeTabs[15].active_tab !== 1
                    ? ListTrailingBadgeOneLineIconJsx.default
                    : ListTrailingBadgeOneLineIconHTML.default
                }
                ListTrailingBadgeTwoLinesIcon={
                  activeTabs[15].active_tab !== 1
                    ? ListTrailingBadgeTwoLinesIconJsx.default
                    : ListTrailingBadgeTwoLinesIconHTML.default
                }
                ListTrailingBadgeThreeLinesIcon={
                  activeTabs[15].active_tab !== 1
                    ? ListTrailingBadgeThreeLinesIconJsx.default
                    : ListTrailingBadgeThreeLinesIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingSwitch">Lists with trailing switch</h3>
          <PreviewBlock
            id="ListsWithTrailingSwitch"
            HtmlComponent={ListTrailingSwitchOneLineHTML.default}
            JsxComponent={ListTrailingSwitchOneLineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingSwitchPreview
                ListTrailingSwitchOneLine={
                  activeTabs[16].active_tab !== 1
                    ? ListTrailingSwitchOneLineJsx.default
                    : ListTrailingSwitchOneLineHTML.default
                }
                ListTrailingSwitchTwoLines={
                  activeTabs[16].active_tab !== 1
                    ? ListTrailingSwitchTwoLinesJsx.default
                    : ListTrailingSwitchTwoLinesHTML.default
                }
                ListTrailingSwitchThreeLines={
                  activeTabs[16].active_tab !== 1
                    ? ListTrailingSwitchThreeLinesJsx.default
                    : ListTrailingSwitchThreeLinesHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="ListsWithTrailingSwitchAndLeadingIcon">
            Lists with trailing switch and leading icon
          </h3>
          <PreviewBlock
            id="ListsWithTrailingSwitchAndLeadingIcon"
            HtmlComponent={ListTrailingSwitchOneLineIconHTML.default}
            JsxComponent={ListTrailingSwitchOneLineIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 py-9"
            >
              <ListsWithTrailingSwitchAndLeadingIconPreview
                ListTrailingSwitchOneLineIcon={
                  activeTabs[17].active_tab !== 1
                    ? ListTrailingSwitchOneLineIconJsx.default
                    : ListTrailingSwitchOneLineIconHTML.default
                }
                ListTrailingSwitchTwoLinesIcon={
                  activeTabs[17].active_tab !== 1
                    ? ListTrailingSwitchTwoLinesIconJsx.default
                    : ListTrailingSwitchTwoLinesIconHTML.default
                }
                ListTrailingSwitchThreeLinesIcon={
                  activeTabs[17].active_tab !== 1
                    ? ListTrailingSwitchThreeLinesIconJsx.default
                    : ListTrailingSwitchThreeLinesIconHTML.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>Use lists to:</p>
          <ul>
            <li>
              Organise vertically a groups of text and images (like a list of
              users, products etc). 
            </li>
            <li>
              Display a list consists of a single continuous column of rows,
              with each row representing a list item.
            </li>
            <li>
              Use lists for communicating or selecting discreet items, such as
              choosing from a set of products.
            </li>
          </ul>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>List items come in three sizes: </p>
          <ul>
            <li>
              <strong>One-line:</strong> single-line list items contain a
              maximum of one line of text.
            </li>
            <li>
              <strong>Two-line: </strong> two-line list items contain a maximum
              of two lines of text.
            </li>
            <li>
              <strong>Three-line:</strong>three-line list items contain a
              maximum of three lines of text.
            </li>
          </ul>
          <p>Each list item can contain:</p>
          <ul>
            <li>
              <strong>Container: </strong>with 0.75rem (12pixels) top and bottom
              padding and 1rem (16px) left and right padding.
            </li>
            <li>
              <strong>Headline:</strong> 1rem (16px) font size.
            </li>
            <li>
              <strong>Supporting text:</strong>0.875rem (14px) font size.
            </li>
            <li>
              <strong>Trailing supporting text:</strong>0.75rem (12px) font
              size.
            </li>
            <li>
              <strong>Trailing or leading icon:</strong>a 1.5rem (24px) icon.
            </li>
            <li>
              <strong>Selection control:</strong>{" "}
              <Link to="/components/form-elements/checkboxes/">checkbox</Link> ,{" "}
              <Link to="/components/form-elements/radio-groups/">
                radio button,
              </Link>{" "}
              <Link to="/components/form-elements/toggles/">toggle</Link>, etc.
            </li>

            <li>
              <strong>Trailing badge:</strong> a trainling{" "}
              <Link to="/components/badges/">badge</Link>.
            </li>
            <li>
              <strong>Leading image: </strong>a image element.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default ListsPage
