import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingIconAndLeadingAvatarPreview(props) {
  const {
    ListTrailingIconOneLineAvatar,
    ListTrailingIconTwoLinesAvatar,
    ListTrailingIconThreeLinesAvatar,
  } = props
  return (
    <div class="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingIconOneLineAvatar}
        componentName="ListTrailingIconOneLineAvatar"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="self-start">
                <a
                  href="#"
                  class="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span class="sr-only"> offline </span>
                  </span>
                </a>
              </div>

              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Ellen Ripley
                </h4>
              </div>

              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="self-start">
                <a
                  href="#"
                  class="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Thomas Anderson
                </h4>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="self-start">
                <a
                  href="#"
                  class="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Luke Skywalker
                </h4>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="self-start">
                <a
                  href="#"
                  class="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Sarah Connor
                </h4>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="self-start">
                <a
                  href="#"
                  class="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span class="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 class="w-full truncate text-base text-slate-700">
                  Captain James T. Kirk
                </h4>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingIconTwoLinesAvatar}
        componentName="ListTrailingIconTwoLinesAvatar"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span class="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Ellen Ripley
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  A tough, resourceful space officer and alien-fighting
                  survivor.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Thomas Anderson
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  The chosen one, a hacker who can bend the rules of reality.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Luke Skywalker
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  A Jedi warrior who fights against the dark side of the Force.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Sarah Connor
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  A fierce resistance leader who fights against machines and
                  cyborgs from the future.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span class="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Captain James T. Kirk
                </h4>
                <p class="w-full truncate text-sm text-slate-500">
                  A charismatic and adventurous Starfleet captain who explores
                  the final frontier.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingIconThreeLinesAvatar}
        componentName="ListTrailingIconThreeLinesAvatar"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0 self-start">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span class="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Ellen Ripley
                </h4>
                <p class="w-full text-sm text-slate-500">
                  A tough, resourceful space officer and survivor.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0 self-start">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Thomas Anderson
                </h4>
                <p class="w-full text-sm text-slate-500">
                  The chosen one, a hacker who can bend the rules.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0 self-start">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Luke Skywalker
                </h4>
                <p class="w-full text-sm text-slate-500">
                  A Jedi warrior who fights against the dark side.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0 self-start">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span class="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Sarah Connor
                </h4>
                <p class="w-full text-sm text-slate-500">
                  A fierce resistance leader who fights machines.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="shrink-0 self-start">
                <a
                  href="#"
                  class="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    class="max-w-full rounded-full"
                  />
                  <span class=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span class="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div class="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <h4 class="w-full truncate text-base text-slate-700">
                  Captain James T. Kirk
                </h4>
                <p class="w-full text-sm text-slate-500">
                  A charismatic and adventurous captain.
                </p>
              </div>
              <a
                href="#"
                class="flex h-6 w-6 items-center justify-center text-xs text-slate-500 transition-colors hover:text-emerald-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
                <span class="sr-only">user options</span>
              </a>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingIconAndLeadingAvatarPreview
