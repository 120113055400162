import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingTextAndLeadingImagePreview(props) {
  const {
    ListTrailingTextOneLineImage,
    ListTrailingTextTwoLinesImage,
    ListTrailingTextThreeLinesImage,
  } = props

  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingTextOneLineImage}
        componentName="ListTrailingTextOneLineImage"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Velocity Boost
                </h4>
              </div>
              <div className="text-xs text-slate-400">$234</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Gravity Glide
                </h4>
              </div>
              <div className="text-xs text-slate-400">$189</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  S4 Turbo Charge
                </h4>
              </div>
              <div className="text-xs text-slate-400">$98</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Flex Fit
                </h4>
              </div>
              <div className="text-xs text-slate-400">$89</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  className="w-8 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Endurance Pro
                </h4>
              </div>
              <div className="text-xs text-slate-400">$78</div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingTextTwoLinesImage}
        componentName="ListTrailingTextTwoLinesImage"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Velocity Boost
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Available in 2-3 days.
                </p>
              </div>
              <div className="text-xs text-slate-400">$234</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Gravity Glide
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Available now.
                </p>
              </div>
              <div className="text-xs text-slate-400">$189</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  S4 Turbo Charge
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Available in 2-3 days.
                </p>
              </div>
              <div className="text-xs text-slate-400">$98</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Flex Fit
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Available in 7-10 days.
                </p>
              </div>
              <div className="text-xs text-slate-400">$89</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  className="w-12 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Endurance Pro
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Available now.
                </p>
              </div>
              <div className="text-xs text-slate-400">$78</div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingTextThreeLinesImage}
        componentName="ListTrailingTextThreeLinesImage"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Velocity Boost
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Color: Green <br />
                  Size: 10.5
                </p>
              </div>
              <div className="text-xs text-slate-400">$234</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Gravity Glide
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Color: Cream <br />
                  Size: 9.5
                </p>
              </div>
              <div className="text-xs text-slate-400">$189</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  S4 Turbo Charge
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Color: Red <br />
                  Size: 9.5
                </p>
              </div>
              <div className="text-xs text-slate-400">$98</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Flex Fit
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Color: Brown <br />
                  Size: 11
                </p>
              </div>
              <div className="text-xs text-slate-400">$89</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  className="w-16 rounded"
                />
              </div>

              <div className="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <h4 className="w-full truncate text-base text-slate-700">
                  Endurance Pro
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Color: Black <br />
                  Size: 9
                </p>
              </div>
              <div className="text-xs text-slate-400">$78</div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingTextAndLeadingImagePreview
