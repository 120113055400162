import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingCheckboxAndLeadingAvatarPreview(props) {
  const {
    ListTrailingCheckboxOneLineAvatar,
    ListTrailingCheckboxTwoLinesAvatar,
    ListTrailingCheckboxThreeLinesAvatar,
  } = props

  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxOneLineAvatar}
        componentName="ListTrailingCheckboxOneLineAvatar"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>

              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  for="id-a01ab"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Ellen Ripley
                </label>
              </div>

              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a01ab"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  for="id-a02ab"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Thomas Anderson
                </label>
              </div>

              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a02ab"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  for="id-c01c"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Luke Skywalker
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a03ab"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  for="id-a04ab"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Sarah Connor
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a04ab"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="self-start">
                <a
                  href="#"
                  className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="32"
                    height="32"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  for="id-a05ab"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Captain James T. Kirk
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a05ab"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxTwoLinesAvatar}
        componentName="ListTrailingCheckboxTwoLinesAvatar"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a01ac"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Ellen Ripley
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  A tough, resourceful space officer and alien-fighting
                  survivor.
                </p>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a01ac"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a02ac"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Thomas Anderson
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  The chosen one, a hacker who can bend the rules of reality.
                </p>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a02ac"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a03ac"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Luke Skywalker
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  A Jedi warrior who fights against the dark side of the Force.
                </p>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a03ac"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a04ac"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Sarah Connor
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  A fierce resistance leader who fights against machines and
                  cyborgs from the future.
                </p>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a04ac"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="shrink-0">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a05ac"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Captain James T. Kirk
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  A charismatic and adventurous Starfleet captain who explores
                  the final frontier.
                </p>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a05ac"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxThreeLinesAvatar}
        componentName="ListTrailingCheckboxThreeLinesAvatar"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=1"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a01ad"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Ellen Ripley
                </label>
                <p className="w-full text-sm text-slate-500">
                  A tough, resourceful space officer and survivor.
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative top-1 flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a01ad"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=3"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a02ad"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Thomas Anderson
                </label>
                <p className="w-full text-sm text-slate-500">
                  The chosen one, a hacker who can bend the rules.
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative top-1 flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a02ad"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=7"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a03ad"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Luke Skywalker
                </label>
                <p className="w-full text-sm text-slate-500">
                  A Jedi warrior who fights against the dark side.
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative top-1 flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a03ad"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=5"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-wuiEmerald-500 p-1 text-sm text-white">
                    <span className="sr-only"> online </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a04ad"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Sarah Connor
                </label>
                <p className="w-full text-sm text-slate-500">
                  A fierce resistance leader who fights machines.
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative top-1 flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a04ad"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="shrink-0 self-start">
                <a
                  href="#"
                  className="relative flex h-10 w-10 items-center justify-center rounded-full text-white"
                >
                  <img
                    src="https://i.pravatar.cc/40?img=11"
                    alt="user name"
                    title="user name"
                    width="40"
                    height="40"
                    className="max-w-full rounded-full"
                  />
                  <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
                    <span className="sr-only"> offline </span>
                  </span>
                </a>
              </div>
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  for="id-a05ad"
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Captain James T. Kirk
                </label>
                <p className="w-full text-sm text-slate-500">
                  A charismatic and adventurous captain.
                </p>
              </div>
              <div className="flex items-center">
                <div className="relative top-1 flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a05ad"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingCheckboxAndLeadingAvatarPreview
