import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function TrailingTextListPreview(props) {
  const {
    ListTrailingTextOneLine,
    ListTrailingTextTwoLines,
    ListTrailingTextThreeLines,
  } = props
  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingTextOneLine}
        componentName="ListTrailingTextOneLine"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Responsive Web Design
                </h4>
              </div>
              <div className="text-xs text-slate-400">100+</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Front-End Frameworks
                </h4>
              </div>
              <div className="text-xs text-slate-400">48</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Web Accessibility</h4>
              </div>
              <div className="text-xs text-slate-400">31</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Backend Development
                </h4>
              </div>
              <div className="text-xs text-slate-400">17</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Web Security</h4>
              </div>
              <div className="text-xs text-slate-400">4</div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingTextTwoLines}
        componentName="ListTrailingTextTwoLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="text-base text-slate-700">
                  Responsive Web Design
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Learn how to create websites that look great on any device.
                </p>
              </div>
              <div className="text-xs text-slate-400">100+</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="text-base text-slate-700">
                  Front-End Frameworks
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Explore the world of popular front-end frameworks like React,
                  Vue, and Angular.
                </p>
              </div>
              <div className="text-xs text-slate-400">48</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="text-base text-slate-700">Web Accessibility</h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Make your websites more inclusive and accessible to all users.
                </p>
              </div>
              <div className="text-xs text-slate-400">31</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="text-base text-slate-700">
                  Backend Development
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Discover the tools and technologies used to build the
                  server-side.
                </p>
              </div>
              <div className="text-xs text-slate-400">17</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0 overflow-hidden">
                <h4 className="text-base text-slate-700">Web Security</h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Learn how to protect your websites from hackers and other
                  security.
                </p>
              </div>
              <div className="text-xs text-slate-400">4</div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingTextThreeLines}
        componentName="ListTrailingTextThreeLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">
                  Responsive Web Design
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Learn how to create websites that look great on any device.
                </p>
              </div>
              <div className="text-xs text-slate-400">100+</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">
                  Front-End Frameworks
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Popular front-end frameworks like React, Vue, and Angular.
                </p>
              </div>
              <div className="text-xs text-slate-400">48</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Web Accessibility</h4>
                <p className="w-full text-sm text-slate-500">
                  Make your websites more inclusive and accessible to all users.
                </p>
              </div>
              <div className="text-xs text-slate-400">31</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">
                  Backend Development
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Discover the tools and technologies used to build the
                  server-side.
                </p>
              </div>
              <div className="text-xs text-slate-400">17</div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Web Security</h4>
                <p className="w-full text-sm text-slate-500">
                  Learn how to protect your websites from hackers and other
                  security.
                </p>
              </div>
              <div className="text-xs text-slate-400">4</div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default TrailingTextListPreview
