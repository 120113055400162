import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function SimpleTextListsPreview(props) {
  const { ListTextOneLine, ListTextTwoLines, ListTextThreeLines } = props

  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTextOneLine}
        componentName="ListTextOneLine"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Faster Development
                </h4>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Mobile-First Approach
                </h4>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Customizable</h4>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">
                  Low Learning Curve
                </h4>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700 ">Scalability</h4>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTextTwoLines}
        componentName="ListTextTwoLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Faster Development</h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Rapidly build UI with pre-designed components.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">
                  Mobile-First Approach
                </h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Optimize design for mobile and small screens.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Customizable</h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Easily modify design and color scheme to fit brand.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Low Learning Curve</h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Simple and intuitive syntax for CSS thats easy to learn and
                  progress.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Scalability</h4>
                <p className="w-full truncate text-sm text-slate-500">
                  Easily maintainable and reusable code for large projects.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
      <CopyComponent
        copyToClipboardCode={ListTextThreeLines}
        componentName="ListTextThreeLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Faster Development</h4>
                <p className="w-full text-sm text-slate-500">
                  Rapidly build UI with pre-designed components and blocks.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">
                  Mobile-First Approach
                </h4>
                <p className="w-full text-sm text-slate-500">
                  Optimize design for mobile first and then for larger screens.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Customizable</h4>
                <p className="w-full text-sm text-slate-500">
                  Easily modify design and color scheme to fit brand and
                  project.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Low Learning Curve</h4>
                <p className="w-full text-sm text-slate-500">
                  Simple and intuitive syntax for CSS thats easy to learn and
                  progress.
                </p>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full flex-col items-start justify-center gap-0">
                <h4 className="text-base text-slate-700">Scalability</h4>
                <p className="w-full text-sm text-slate-500">
                  Easily maintainable and reusable code for large projects.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}
export default SimpleTextListsPreview
