import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListTrailingCheckboxListWithLeadingImagePreview(props) {
  const {
    ListTrailingCheckboxOneLineImage,
    ListTrailingCheckboxTwoLinesImage,
    ListTrailingCheckboxThreeLinesImage,
  } = props

  return (
    <div class="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxOneLineImage}
        componentName="ListTrailingCheckboxOneLineImage"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  class="w-8 rounded"
                />
              </div>

              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  for="id-a13a"
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Velocity Boost
                </label>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a13a"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  class="w-8 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  for="id-a13b"
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Gravity Glide
                </label>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a13b"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  class="w-8 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  for="id-a13c"
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  S4 Turbo Charge
                </label>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a13c"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  class="w-8 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  for="id-a13d"
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Flex Fit
                </label>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a13d"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  class="w-8 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  for="id-a13e"
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                >
                  Endurance Pro
                </label>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-a13e"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxTwoLinesImage}
        componentName="ListTrailingCheckboxTwoLinesImage"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  class="w-12 rounded"
                />
              </div>

              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b13a"
                >
                  Velocity Boost
                </label>
                <p class="w-full truncate text-sm text-slate-500">
                  Available in 2-3 days.
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-b13a"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  class="w-12 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b13b"
                >
                  Gravity Glide
                </label>
                <p class="w-full truncate text-sm text-slate-500">
                  Available now.
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-b13b"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  class="w-12 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b13c"
                >
                  S4 Turbo Charge
                </label>
                <p class="w-full truncate text-sm text-slate-500">
                  Available in 2-3 days.
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-b13c"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  class="w-12 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b13d"
                >
                  Flex Fit
                </label>
                <p class="w-full truncate text-sm text-slate-500">
                  Available in 7-10 days.
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-b13d"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  class="w-12 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b13e"
                >
                  Endurance Pro
                </label>
                <p class="w-full truncate text-sm text-slate-500">
                  Available now.
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-b13e"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxThreeLinesImage}
        componentName="ListTrailingCheckboxThreeLinesImage"
      >
        <div class="w-80 rounded bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul class="divide-y divide-slate-100">
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex shrink-0 items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-01.jpeg"
                  alt="product image"
                  class="w-16 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c13a"
                >
                  Velocity Boost
                </label>
                <p class="w-full text-sm text-slate-500">
                  Color: Green <br />
                  Size: 10.5
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-c13a"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-02.jpeg"
                  alt="product image"
                  class="w-16 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c13b"
                >
                  Gravity Glide
                </label>
                <p class="w-full text-sm text-slate-500">
                  Color: Cream <br />
                  Size: 9.5
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-c13b"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-03.jpeg"
                  alt="product image"
                  class="w-16 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c13c"
                >
                  S4 Turbo Charge
                </label>
                <p class="w-full text-sm text-slate-500">
                  Color: Red <br />
                  Size: 9.5
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-c13c"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-04.jpeg"
                  alt="product image"
                  class="w-16 rounded"
                />
              </div>

              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c13d"
                >
                  Flex Fit
                </label>
                <p class="w-full text-sm text-slate-500">
                  Color: Brown <br />
                  Size: 11
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-c13d"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="flex items-center gap-4 px-4 py-3">
              <div class="flex items-center self-center">
                <img
                  src="https://tailwindmix.b-cdn.net/products/product-shoe-05.jpeg"
                  alt="product image"
                  class="w-16 rounded"
                />
              </div>
              <div class="flex min-h-[2rem] min-w-0 flex-1 flex-col items-start justify-center gap-0">
                <label
                  class="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c13e"
                >
                  Endurance Pro
                </label>
                <p class="w-full text-sm text-slate-500">
                  Color: Black <br />
                  Size: 9
                </p>
              </div>
              <div class="flex items-center self-center">
                <div class="relative flex flex-wrap items-center">
                  <input
                    class="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-c13e"
                  />
                  <svg
                    class="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListTrailingCheckboxListWithLeadingImagePreview
