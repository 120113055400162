import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingSwitchPreview(props) {
  const {
    ListTrailingSwitchOneLine,
    ListTrailingSwitchTwoLines,
    ListTrailingSwitchThreeLines,
  } = props
  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingSwitchOneLine}
        componentName="ListTrailingSwitchOneLine"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-30a"
                >
                  Push Notifications
                </label>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-30a"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-30b"
                >
                  Location Services
                </label>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  value=""
                  id="id-30b"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-30c"
                >
                  In-App Sound{" "}
                </label>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-30c"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-30d"
                >
                  Dark Mode
                </label>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-30d"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-30e"
                >
                  Analytics Tracking
                </label>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  value=""
                  id="id-30e"
                />
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingSwitchTwoLines}
        componentName="ListTrailingSwitchTwoLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-31a"
                >
                  Push Notifications
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Get instant updates about new messages and alerts.
                </p>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-31a"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-31b"
                >
                  Location Services
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Allow us to find you and suggest relevant content.{" "}
                </p>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  value=""
                  id="id-31b"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-31c"
                >
                  In-App Sound{" "}
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  {" "}
                  Turn on/off sounds for button clicks and alerts.
                </p>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-31c"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-31d"
                >
                  Dark Mode
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Switch to a darker color scheme for a better viewing
                  experience.{" "}
                </p>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-31d"
                />
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-31e"
                >
                  Analytics Tracking
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Help us improve by sharing usage data with us.{" "}
                </p>
              </div>

              <div className="relative flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  value=""
                  id="id-31e"
                />
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingSwitchThreeLines}
        componentName="ListTrailingSwitchThreeLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-35a"
                >
                  Push Notifications
                </label>
                <p className="w-full text-sm text-slate-500">
                  Get instant updates about new messages and alerts.
                </p>
              </div>

              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-35a"
                />
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-35b"
                >
                  Location Services
                </label>
                <p className="w-full text-sm text-slate-500">
                  Allow us to find you and suggest relevant content.{" "}
                </p>
              </div>

              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  value=""
                  id="id-35b"
                />
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-35c"
                >
                  In-App Sound{" "}
                </label>
                <p className="w-full text-sm text-slate-500">
                  {" "}
                  Turn on/off sounds for button clicks and alerts.
                </p>
              </div>

              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-35c"
                />
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-35d"
                >
                  Dark Mode
                </label>
                <p className="w-full text-sm text-slate-500">
                  Switch to a darker color scheme for a better viewing
                  experience.{" "}
                </p>
              </div>

              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  checked
                  id="id-35d"
                />
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-35e"
                >
                  Analytics Tracking
                </label>
                <p className="w-full text-sm text-slate-500">
                  Help us improve by sharing usage data with us.{" "}
                </p>
              </div>

              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer relative h-4 w-8 cursor-pointer appearance-none rounded-lg bg-slate-300 transition-colors after:absolute after:top-0 after:left-0 after:h-4 after:w-4 after:rounded-full after:bg-slate-500 after:transition-all checked:bg-emerald-200 checked:after:left-4 checked:after:bg-emerald-500 hover:bg-slate-400 after:hover:bg-slate-600 checked:hover:bg-emerald-300 checked:after:hover:bg-emerald-600 focus:outline-none checked:focus:bg-emerald-400 checked:after:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-slate-300"
                  type="checkbox"
                  value=""
                  id="id-35e"
                />
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingSwitchPreview
