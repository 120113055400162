import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

function ListsWithTrailingCheckboxPreview(props) {
  const {
    ListTrailingCheckboxOneLine,
    ListTrailingCheckboxTwoLines,
    ListTrailingCheckboxThreeLines,
  } = props
  return (
    <div className="flex flex-col items-center justify-between gap-8 lg:items-start xl:flex-row">
      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxOneLine}
        componentName="ListTrailingCheckboxOneLine"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-a01a"
                >
                  Design Layout{" "}
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-a01a"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b01a"
                >
                  Deveop features{" "}
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-b01a"
                  />

                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c01a"
                >
                  Optimize SEO{" "}
                </label>
              </div>

              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-c01a"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-d01a"
                >
                  Test Performance{" "}
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    checked
                    id="id-d01a"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] flex-1 flex-col items-start justify-center gap-0 overflow-hidden">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-e01a"
                >
                  Maintain Security{" "}
                </label>
              </div>
              <div className="flex items-center self-center">
                <div className="relative flex flex-wrap items-center">
                  <input
                    className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                    type="checkbox"
                    value=""
                    id="id-e01a"
                  />
                  <svg
                    className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="graphics-symbol"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxTwoLines}
        componentName="ListTrailingCheckboxTwoLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-a02a"
                >
                  Design Layout
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Creating visual mockups of the website's layout and
                  organization.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  checked
                  id="id-a02a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b02a"
                >
                  Deveop features
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  {" "}
                  Writing code to add functionality to a website, such as forms,
                  interactive elements, and database integration.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  value=""
                  id="id-b02a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c02a"
                >
                  Optimize SEO{" "}
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Analyzing and improving a website's search engine rankings
                  through various techniques, including keyword research and
                  on-page optimization.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  value=""
                  id="id-c02a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-d02a"
                >
                  Test Performance{" "}
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Measuring a website's speed, responsiveness, and functionality
                  to ensure optimal user experience and identify areas for
                  improvement.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  value=""
                  id="id-d02a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-center gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-e02a"
                >
                  Maintain Security{" "}
                </label>
                <p className="w-full truncate text-sm text-slate-500">
                  Implementing measures to protect a website from cyber threats,
                  such as malware, hacking attempts, and data breaches.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  checked
                  id="id-e02a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={ListTrailingCheckboxThreeLines}
        componentName="ListTrailingCheckboxThreeLines"
      >
        <div className="w-80 max-w-full rounded  bg-white shadow-md shadow-slate-200 xl:w-[18rem]  ">
          <ul className="divide-y divide-slate-100">
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c01c"
                >
                  Design Layout
                </label>
                <p className="w-full text-sm text-slate-500">
                  Creating visual mockups of the website's layout and
                  organization.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  checked
                  id="id-c01c"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-b03a"
                >
                  Deveop features
                </label>
                <p className="w-full text-sm text-slate-500">
                  {" "}
                  Writing code to add functionality to a website, such as forms.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  value=""
                  id="id-b03a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-c03a"
                >
                  Optimize SEO{" "}
                </label>
                <p className="w-full text-sm text-slate-500">
                  Analyzing and improving a website's search engine rankings.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  value=""
                  id="id-c03a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-d03a"
                >
                  Test Performance{" "}
                </label>
                <p className="w-full text-sm text-slate-500">
                  Measuring a website's speed, responsiveness, and
                  functionality.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  checked
                  id="id-d03a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
            <li className="flex items-start gap-4 px-4 py-3">
              <div className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0">
                <label
                  className="w-full cursor-pointer truncate text-base text-slate-700"
                  for="id-e03a"
                >
                  Maintain Security{" "}
                </label>
                <p className="w-full text-sm text-slate-500">
                  Implementing measures to protect a website from cyber threats.
                </p>
              </div>
              <div className="relative top-1 flex flex-wrap items-center">
                <input
                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-emerald-500 checked:bg-emerald-500 checked:hover:border-emerald-600 checked:hover:bg-emerald-600 focus:outline-none checked:focus:border-emerald-700 checked:focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                  type="checkbox"
                  value=""
                  id="id-e03a"
                />
                <svg
                  className="pointer-events-none absolute top-0 left-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="graphics-symbol"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </CopyComponent>
    </div>
  )
}

export default ListsWithTrailingCheckboxPreview
